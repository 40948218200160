<template>
  <v-container v-if="isOpen" :style="style">
    <v-alert
      v-if="title"
      dense
      :icon="icon ? icon : 'mdi-format-list-bulleted-square'"
      border="left"
      color="primary"
      text
      type="success"
    >
      <div>{{ title }}</div>
    </v-alert>
    <v-row>
      <v-col cols="12" lg="12" md="12" sm="12">
        <v-text-field
          :label="label"
          type="text"
          required
          @change.native="filterFunction($event.target.value, items)"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <div
        class="validation-error pb-1 mb-2"
        v-if="isRequired && selectedItems.length === 0"
      >
        {{ isRequiredMessage }}
      </div>
    </v-row>
    <v-row>
      <v-col cols="12" lg="5" md="4" sm="12">
        <select class="dual-multiselect" multiple>
          <option
            v-for="item in items"
            @click="highlightItem(item, 'left')"
            @dblclick="addItem(item)"
            :value="item"
            :key="item.id"
          >
            {{ item.name }}
          </option>
        </select>
      </v-col>
      <v-col cols="12" lg="2" md="2" sm="12">
        <div class="button-container">
          <v-btn
            class="white--text"
            color="primary"
            :disabled="!left"
            small
            @click="addItems"
          >
            <v-icon>mdi-menu-right</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            small
            class="remove-button white--text"
            :disabled="items.length === 0"
            @click="addAll"
          >
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            small
            :disabled="!right"
            class="remove-button white--text"
            @click="removeItems"
          >
            <v-icon>mdi-menu-left</v-icon>
          </v-btn>
          <v-btn
            color="primary"
            small
            class="remove-button white--text"
            :disabled="selectedItems && selectedItems.length === 0"
            @click="removeAll"
          >
            <v-icon>mdi-chevron-double-left</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" lg="5" md="4" sm="12">
        <select class="dual-multselect" multiple>
          <option
            v-for="item in selectedItems"
            @click="highlightItem(item, 'right')"
            @dblclick="removeItem(item)"
            :value="item"
            :key="item.id"
          >
            {{ item.name }}
          </option>
        </select>
      </v-col>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DualMultiSelects",

  props: {
    title: { type: String, required: false },
    width: { type: String, required: false },
    icon: { type: String, required: false },
    isRequiredMessage: { type: String, required: false },
    isRequired: { type: Boolean, required: false, default: false },
    items: { type: Array, required: true },
    isOpen: { type: Boolean, required: true, default: false },
    label: { type: String, required: false },
    columnName: { type: String, required: true },
    filterFunction: { type: Function, required: true },
  },
  data() {
    return {
      search: "",
      search2: null,
      itemsToSelect: [],
      selectedItems: [],
      originalItems: [],

      right: false,
      left: false,
    };
  },

  methods: {
    // searchItems(term) {
    //   this.filterFunction(term);
    // },
    searchOptions() {
      this.$emit(this.findOptionFunction(this.search2));
    },
    highlightItem(item, location) {
      this.right = location === "right" ? true : false;
      this.left = location === "left" ? true : false;

      this.itemsToSelect.push(item);
    },

    selectAll() {
      this.items.forEach((item) => {
        this.selectedItems.push(item);
      });
      this.items = [];
      this.itemsToSelect = [];
      this.$emit("input", this.selectedItems);
      this.refreshList();
    },
    addItem(item) {
      const el = this.items.find((el) => el.id === item.id);
      const idx = this.items.indexOf(el);
      if (idx !== -1) {
        this.items.splice(idx, 1);
        this.selectedItems.push(el);
        this.itemsToSelect = [];
      }
      this.$emit("input", this.selectedItems);
      this.refreshList();
    },
    removeItem(item) {
      const el = this.selectedItems.find((el) => el.id === item.id);
      const idx = this.selectedItems.indexOf(el);
      if (idx !== -1) {
        this.selectedItems.splice(idx, 1);
        this.items.push(el);
        this.itemsToSelect = [];
      }
      this.$emit("input", this.selectedItems);
      this.refreshList();
    },
    addItems() {
      this.itemsToSelect.forEach((item) => {
        const idx = this.items.map((i) => i.id).indexOf(item.id);
        if (idx !== -1) {
          this.items.splice(idx, 1);
          this.selectedItems.push(item);
          this.itemsToSelect = [];
        }
      });
      this.$emit("input", this.selectedItems);
      this.refreshList();
    },
    removeItems() {
      this.itemsToSelect.forEach((item) => {
        const idx = this.selectedItems.map((i) => i.id).indexOf(item.id);
        if (idx !== -1) {
          this.selectedItems.splice(idx, 1);
          this.items.push(item);
          this.itemsToSelect = [];
        }
      });
      this.$emit("input", this.selectedItems);
      this.refreshList();
    },
    removeAll() {
      this.selectedItems.forEach((item) => {
        this.items.push(item);
      });
      this.selectedItems = [];
      this.$emit("input", this.selectedItems);
      this.refreshList();
    },
    addAll() {
      this.items.forEach((item) => {
        this.selectedItems.push(item);
      });
      this.$emit("input", this.selectedItems);
      this.refreshList();
    },
    refreshList() {
      this.right = false;
      this.left = false;

      this.selectedItems.forEach((item) => {
        const idx = this.items.map((i) => i.id).indexOf(item.id);
        if (idx !== -1) {
          this.items.splice(idx, 1);
        } else {
          this.items;
        }
      });
    },

    restoreArray() {
      this.selectedItems.forEach((item) => {
        const idx = this.items.map((i) => i).indexOf(item);
        if (idx === -1) {
          this.items.push(item);
        }
      });
      this.selectedItems = [];
    },
  },

  mounted() {
    this.selectedItems = this.$attrs.value || [];
    this.refreshList();
  },

  computed: {
    filteredData() {
      return this.items.map((item) => {
        item["itemName"] = item[this.columnName];
      });
    },

    style() {
      return {
        // 'background-color: ' :this.hovering ? this.color: 'red'
        width: this.width,
      };
    },
  },

  watch: {
    isOpen(open) {
      if (!open) {
        this.restoreArray();
      }
    },

    $attrs(newVal) {
      this.selectedItems = newVal.value || [];
      this.refreshList();
    },

    search(newVal) {
      // console.log(newVal);
      return this.filterFunction(newVal, this.items);
    },
  },

  destroyed() {
    this.restoreArray();
    // console.log("destroyed");
    // this.refreshList();
    // this.selectedItems = this.$attrs.value || [];
  },
};
</script>

<style scoped lang="scss">
select {
  display: block;
  overflow: hidden;
  overflow-y: scroll;
  font-size: 14px;
  font-weight: 400;
  min-height: 140px;
  line-height: 1.125;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  background-clip: padding-box;
  padding: 0.4375rem 0.4375rem;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  width: 100%;
  &:active {
    border: 2px solid primary;
  }
}

.button-container {
  position: relative;
  top: 5px;
}

.v-text-field {
  padding-top: 5px !important;
}

.container {
  padding: 0 !important;
}

div[class^="col-"] {
  padding: 0px 6px !important;
}

button {
  margin-top: 5px;
}
.validation-error {
  line-height: 12px;
  word-break: break-word;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  color: #ff5252 !important;
  caret-color: #ff5252 !important;
  flex: 1 1 auto;
  font-size: 12px;
  min-height: 14px;
  min-width: 1px;
  position: relative;
}
</style>
